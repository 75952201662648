import React from 'react'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/react'

import bg from '../images/bg.png'
import { colors, fontSizes, space } from './theme'

export const breakpoints = {
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
}

export const GlobalStyle = () => (
  <Global
    styles={css`
      html {
        font-family: sans-serif;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
      }

      body {
        margin: 0;
        font-family: brother-1816-printed, sans-serif;
        background-image: url(${bg});
        background-position: center top;
        background-size: cover;
        background-repeat: repeat-y;
      }

      p {
        line-height: 1.6;
        font-weight: 400;
      }

      a,
      a:active,
      a:visited {
        color: inherit;
        text-decoration: none;
      }

      a:hover {
        text-decoration: underline;
      }

      h1,
      h2 {
        font-family: brother-1816-printed, sans-serif;
        font-weight: 700;
        font-style: normal;
      }
    `}
  />
)

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1140px;
  padding: 0 1.45rem;
`

export const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ gap }) => (gap ? gap : '2.5rem')};
  grid-row-gap: ${({ gridRowGap }) => (gridRowGap ? gridRowGap : '2.5rem')};
  grid-auto-flow: row dense;

  & > :nth-of-type(4n - 1) {
    grid-column-start: 2;
  }

  @media (max-width: ${breakpoints.l}px) {
    display: block;

    & > * {
      margin-bottom: ${({ gap }) => (gap ? gap : '2.5rem')};
    }

    & > :nth-child(2n) {
      margin-bottom: ${({ gridRowGap }) =>
        gridRowGap ? gridRowGap : '2.5rem'};
    }

    & > :last-child {
      margin-bottom: 0rem;
    }
  }
`

export const ThreeThirdsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-flow: row dense;
  gap: ${({ gap }) => (gap ? gap : '2.5rem')};
  ${({ backgroundColor }) =>
    backgroundColor ? `background-color: ${backgroundColor};` : null};

  @media (max-width: ${breakpoints.l}px) {
    display: block;

    & > * {
      margin-bottom: ${({ gap }) => (gap ? gap : '2.5rem')};
    }

    & > :last-child {
      margin-bottom: 0rem;
    }
  }
`

export const Hero = styled.h1`
  margin-top: ${space[20]};
  margin-bottom: ${space[20]};
  font-size: ${fontSizes['4xl']};
  color: ${colors.darkGrey};
  text-transform: uppercase;
`

export const H1 = styled.h1`
  font-family: brother-1816-printed, sans-serif;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.1em;
  font-size: ${fontSizes['4xl']};
`

export const H2 = styled.h2`
  font-family: brother-1816-printed, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: ${fontSizes['xl']};
  color: ${({ color }) => (color ? color : colors.hergaBlue)};
  text-transform: uppercase;
`

export const H3 = styled.h3`
  font-family: brother-1816-printed, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: ${fontSizes.lg};

  @media (max-width: ${breakpoints.s}px) {
    font-size: ${fontSizes.sm};
  }
`

export const Center = styled.div`
  text-align: center;
`

export const Text = styled.p`
  color: ${({ color }) => (color ? color : 'inherit')};
  line-height: 1.6;
  font-size: ${fontSizes.md};

  @media (max-width: ${breakpoints.s}px) {
    font-size: ${fontSizes.xs};
  }
`
